<template>
    <div>
        <kendo-grid ref="grid"
                    :data-source="dataSource"
                    :pageable="true"
                    :filterable="true"
                    :sortable="true"
                    :columns=columns
                    v-on:detailinit="detailInit"
                    :resizable="true">
        </kendo-grid>
    </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import payrollService from '../Script/PayrollService.js';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'PayrollGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'deleteClick','period','employeeId'],
    data(){
        var period = this.$props.period;
        var employeeId = parseInt(this.$props.employeeId);
        var month = parseInt(period.split("-")[0]);
        var year = parseInt(period.split("-")[1]);

        return{
            dataSource: new kendo.data.DataSource({
                        page: 1,
                        pageSize: kendo_grid.default_grid_pagesize,
                        serverPaging: false,
                        transport: {
                            read: {
                                contentType: "application/json",
                                url: apiBaseUrl,
                                type: "POST",
                                data: function() {
                                    const variables = {
                                        month: month,
                                        year: year
                                    };
                                    if (employeeId == ""){
                                        return { query: payrollService.readQuery(),
                                                    variables: variables };
                                    }
                                    else{
                                        const variables = {
                                            month: month,
                                            year: year,
                                            employee_id: employeeId
                                        };
                                        return { query: payrollService.readQuery(), 
                                                    variables: variables };
                                    }
                                    
                                },
                                beforeSend: function (req) {
                                    req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                                    req.setRequestHeader('Path', window.location.pathname);
                                }
                            },
                            parameterMap: function(options, operation) {
                                return  kendo.stringify({
                                    query: options.query,
                                    variables: options.variables
                                });
                            }
                        },
                        schema: {
                            data: function(response) {
                                if (response.data.GetPayroll == null)
                                    return [];
                                else
                                    return response.data.GetPayroll;
                            },
                            total: function(response) {
                                if (response.data.GetPayroll == null)                               
                                    return 0;
                                else
                                    return response.data.GetPayroll.length; 
                            }
                        },
                }),
            columns:  [
                { title: "Action", width: 150, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "employee_name", title: "Nama", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "month", title: "Bulan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" } }, 
                { field: "year", title: "Tahun", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" } },
                { field: "total_salary", title: "Total Gaji", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }},
                { field: "total_debt", title: "Total Utang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } }
            ],
        }
    },
    mounted: function(){
        var deleteClick = this.$props.deleteClick;
        var editClick = this.$props.editClick;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;
        
        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            const payrollData = {
                payroll_id: dataItem.payroll_id,
                employee_id: dataItem.employee_id,
                month: dataItem.month,
                year: dataItem.year
            };
            
            editClick(payrollData, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            const payrollData = {
                payroll_id: dataItem.payroll_id,
                employee_id: dataItem.employee_id,
                month: dataItem.month,
                year: dataItem.year
            };

            editClick(payrollData, true);
        })

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.payroll_id);
        })
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionButton("Payroll")
        },
        detailInit: async function (e) {
            const variables = {
                employee_id: e.data.employee_id,
                month: e.data.month,
                year: e.data.year
            };
            
            var data = await payrollService.readQueryPayrollById(variables);
            var html = payrollService.payrollDetailTemplate(data);
            $('<div/>').appendTo(e.detailCell).html(html);
        },
    }
}
</script>

<style scoped>
</style>