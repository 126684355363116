import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class EmployeeHRService {
    readQuery(){
        var query = `query($paging:ServerPaging){
            GetEmployee(Paging:$paging){
                Employee{
                    employee_id,
                    fullname,
                    nik,
                    npwp,
                    married_status,
                    education,
                    phone_number,
                    email,
                    start_work_date,
                    last_work_date,
                    contract_period,
                    probation_period,
                    warning_letter_received,
                    bca_account_number,
                    bca_account_name,
                    employment_status,
                    religion,
                    birthdate,
                    birthplace,
                    blood_group,
                    sex,
                    position,
                    created_at,
                    last_update,
                    department,
                    address,
                    meal_allowance,
                    transport_allowance,
                    daily_salary,
                    fixed_positional_allowance,
                    daily_positional_allowance,
                    motorcycle_rental_allowance,
                    trip_allowance,
                    kost_allowance,
                    pulse_allowance,
                    insurance_subtraction,
                    absence_subtraction,
                    base_salary,
                    craft_subtraction,
                    annual_paid_leave
                }
                Total
            }
        }`;
        return query;
    }

    async getEmployeeHR(id) {
        const variables = {
            id : id
        }
        var query = gql`
            query ($id:Int) {
                GetEmployee (EmployeeId:$id) {
                    Employee {
                        employee_id
                        fullname
                        nik
                        npwp
                        married_status
                        education
                        phone_number
                        email
                        start_work_date
                        last_work_date
                        contract_period
                        probation_period
                        warning_letter_received
                        bca_account_number
                        bca_account_name
                        employment_status
                        religion
                        birthdate
                        birthplace
                        blood_group
                        sex
                        position
                        created_at
                        last_update
                        department
                        address
                        meal_allowance
                        transport_allowance
                        daily_salary
                        fixed_positional_allowance
                        daily_positional_allowance
                        motorcycle_rental_allowance
                        trip_allowance
                        kost_allowance
                        pulse_allowance
                        insurance_subtraction
                        absence_subtraction
                        base_salary
                        craft_subtraction
                        annual_paid_leave
                    }
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);        
        return result.data.GetEmployee.Employee[0];
    }

    async deleteQuery(variables){
        const query = gql`mutation($id:Int!){
            DeleteEmployee(EmployeeId:$id)
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)    
    }

    async addQuery(variables){
        const query = gql`
            mutation ($data:NewEmployee!) {
                CreateEmployee (NewEmployee:$data)
            }
        `
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async editQuery(variables){
        const query = gql`
            mutation ($id:Int!, $data:NewEmployee!) {
                UpdateEmployee (EmployeeId:$id, UpdateEmployee:$data)
            }
        `
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}


export default new EmployeeHRService();