import gql from 'graphql-tag';
import { globalfunc } from '../../../../shared/GlobalFunction.js';

class AttendanceService {
    readQuery(){
        var query = `query($month:Int!,$year:Int!){
            GetAttendance(month:$month,year:$year){
                attendance_id
                employee_Id
                fullname
                department
                position
                month
                year
                total_attendance
                total_working_day
                total_unpaid_leave
                total_paid_leave
                created_at
                last_update
                total_late
                annual_paid_leave
                amount_of_using_paid_leave
                remaining_paid_leave
            }
        }`;
        return query;
    }
    
    async getAttendanceData(month, year, employeeId) {
        const variables = {
            month : month,
            year : year,
            id : employeeId
        }
        var query = gql`
            query ($month:Int!, $year:Int!, $id:Int) {
                GetAttendance (month:$month, year:$year, employee_Id:$id) {
                    attendance_id
                    employee_Id
                    fullname
                    department
                    position
                    month
                    year
                    total_attendance
                    total_working_day
                    total_unpaid_leave
                    total_paid_leave
                    created_at
                    last_update
                    total_late
                    annual_paid_leave
                    amount_of_using_paid_leave
                    remaining_paid_leave
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        
        return result.data.GetAttendance[0];
    }
    
    async getEmployeeIdQuery(){
        var query = gql`query{
            GetEmployee{
              Employee{
                employee_id,
                fullname
              }
              
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
       
        var arrayData = [];
        if(result.data.GetEmployee.Employee != null){
            for (let i = 0; i < result.data.GetEmployee.Employee.length; i++) {
                var label = result.data.GetEmployee.Employee[i].fullname
                var str = {value:result.data.GetEmployee.Employee[i].employee_id, label:label}
                arrayData.push(str);
            }
        }
        return arrayData;
    }  

    async addQuery(variables){
        const query = gql`
            mutation ($data:NewAttendance!) {
                CreateAttendance (NewAttendance:$data)
            }
        `
        return await globalfunc.defaultApolloMutationDefault(query, variables)

    }

    async deleteQuery(variables){
        const query = gql`
            mutation ($id:Int!) {
                DeleteAttendance (AttendanceId:$id)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editQuery(variables){
        const query = gql`
            mutation ($id:Int!, $data:NewAttendance!) {
                UpdateAttendance (AttendanceId:$id, NewAttendance:$data)
            }
        `
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}


export default new AttendanceService();