<template>
    <div>
        <input hidden type="text" class="form-control" id="EmployeeId" v-model="EmployeeId">
        <div class="modal fade" id="employeeHRModal" tabindex="-1" aria-labelledby="employeeHRModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="employeeHRModalLabel" class="font-weight-bold">{{ModalTitle}}{{Fullname}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CCardBody>
                            <label class="form-label"><h4><b>Profil</b></h4></label>
                            <div class="p-3" width="100%" style="border-style:solid; border-radius: 3px; border-width: thin; border-color:lightgrey">
                                <CRow>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold required">Nama Lengkap</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CInput id="Fullname" v-model="Fullname" class="font-weight-bold" />
                                        <label id="errorFullname" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold required">NIK</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CInput id="NIK" type="number" v-model="NIK" class="font-weight-bold" />
                                        <label id="errorNIK" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold required">Alamat</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CInput id="Address" v-model="Address" class="font-weight-bold" />
                                        <label id="errorAddress" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold">Jenis Kelamin</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CSelect id="Sex" class="font-weight-bold" :options="SexData" :value.sync="Sex" />
                                        <label id="errorSex" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold required">Tempat Lahir</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CInput id="BirthPlace" v-model="BirthPlace" class="font-weight-bold" />
                                        <label id="errorBirthPlace" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold required">Tanggal Lahir</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <date-picker
                                            v-model="Birthdate"
                                            format="DD MMM YYYY"
                                            type="date"
                                            placeholder="Select date"
                                            value-type="YYYY-MM-DD"
                                            style="width:100%"
                                        ></date-picker>
                                        <label id="errorBirthdate" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold required">No. Telp</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CInput id="PhoneNumber" v-model="PhoneNumber" class="font-weight-bold"/>
                                        <label id="errorPhoneNumber" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold required">Email</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CInput id="Email" v-model="Email" class="font-weight-bold" />
                                        <label id="errorEmail" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold">Golongan Darah</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CSelect id="BloodGroup" class="font-weight-bold" :options="BloodGroupData" :value.sync="BloodGroup" />
                                        <label id="errorBloodGroup" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold required">Pendidikan</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CInput id="Education" v-model="Education" class="font-weight-bold" />
                                        <label id="errorEducation" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold required">NPWP</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CInput id="NPWP" v-model="NPWP" class="font-weight-bold" />
                                        <label id="errorNPWP" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold">Status Perkawinan</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CSelect id="MarriedStatus" class="font-weight-bold" @change="onChangeDDLMarriedStatus($event)" :options="MarriedStatusData" :value.sync="MarriedStatus" />
                                        <label id="errorMarriedStatus" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold">Agama</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CSelect id="Religion" class="font-weight-bold" :options="ReligionData" :value.sync="Religion" />
                                        <label id="errorReligion" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                </CRow>
                            </div>
                            <br>
                            
                            <label class="form-label"><h4><b>Info Karyawan</b></h4></label>
                            <div class="p-3" width="100%" style="border-style:solid; border-radius: 3px; border-width: thin; border-color:lightgrey">
                                <CRow class="pb-3">
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold required">Tanggal Awal Bekerja</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <date-picker
                                            v-model="StartWorkDate"
                                            format="DD MMM YYYY"
                                            type="date"
                                            placeholder="Select date"
                                            value-type="YYYY-MM-DD"
                                            style="width:100%"
                                        ></date-picker>
                                        <label id="errorStartWorkDate" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold">Tanggal Akhir Bekerja</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <date-picker
                                            v-model="LastWorkDate"
                                            format="DD MMM YYYY"
                                            type="date"
                                            placeholder="Select date"
                                            value-type="YYYY-MM-DD"
                                            style="width:100%"
                                            :disabled-date="disabledDate"
                                        ></date-picker>
                                        <label id="errorLastWorkDate" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold">Status Karyawan</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CSelect id="EmploymentStatus" @change="onChangeDDLEmpStatus($event)" class="font-weight-bold" :options="EmploymentStatusData" :value.sync="EmploymentStatus" />
                                        <label id="errorEmploymentStatus" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold">Departemen</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CInput id="Department" v-model="Department" class="font-weight-bold" />
                                        <label id="errorDepartment" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold">Periode Kontrak (Bulan)</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CInput id="ContractPeriod" type="number" v-model="ContractPeriod" class="font-weight-bold" :disabled="disabledCP ? false : true"/>
                                        <label id="errorContractPeriod" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold">Posisi</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CInput id="Position" v-model="Position" class="font-weight-bold" />
                                        <label id="errorPosition" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold required">Periode Probation (Bulan)</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CInput id="ProbationPeriod" type="number" v-model="ProbationPeriod" class="font-weight-bold"  :disabled="disabledPP ? false : true"/>
                                        <label id="errorProbationPeriod" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold">Surat Peringatan</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CInput id="WarningLetterReceived" type="number" v-model="WarningLetterReceived" class="font-weight-bold" />
                                        <label id="errorWarningLetterReceived" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold">Gaji Pokok</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CInput id="BaseSalary" type="number" v-model="BaseSalary" class="font-weight-bold" />
                                        <label id="errorBaseSalary" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold">Cuti Tahunan</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CInput id="AnnualPaidLeave" type="number" v-model="AnnualPaidLeave" class="font-weight-bold" />
                                        <label id="errorAnnualPaidLeave" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold">Nama Akun BCA</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CInput id="BCAAccountName" v-model="BCAAccountName" class="font-weight-bold" />
                                        <label id="errorBCAAccountName" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold">No. Rekening BCA</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CInput id="BCAAccountNumber" type="number" v-model="BCAAccountNumber" class="font-weight-bold"/>
                                        <label id="errorBCAAccountNumber" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                </CRow>
                            </div>
                            <br>
                            
                            <label class="form-label"><h4><b>Tunjangan</b></h4></label>
                            <div class="p-3" width="100%" style="border-style:solid; border-radius: 3px; border-width: thin; border-color:lightgrey">
                                <CRow>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold">Tunjangan Makan</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CInput id="MealAllowance" type="number"  v-model="MealAllowance" class="font-weight-bold" />
                                        <label id="errorMealAllowance" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold">Tunjangan Transportasi</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CInput id="TransportAllowance" type="number" v-model="TransportAllowance" class="font-weight-bold" />
                                        <label id="errorTransportAllowance" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                </CRow>
                            
                                <CRow>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold">Tunjangan Karyawan Tetap</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CInput id="FixedPositionalAllowance" type="number" v-model="FixedPositionalAllowance" class="font-weight-bold" />
                                        <label id="errorFixedPositionalAllowance" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold">Daily Positional Allowance</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CInput id="DailyPositionalAllowance" type="number" v-model="DailyPositionalAllowance" class="font-weight-bold" />
                                        <label id="errorDailyPositionalAllowance" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                </CRow>
                                
                                <CRow>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold">Tunjangan Perjalanan</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CInput id="TripAllowance" type="number" v-model="TripAllowance" class="font-weight-bold"/>
                                        <label id="errorTripAllowance" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold">Tunjangan Kendaraan</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CInput id="MotorcycleRentalAllowance" type="number" v-model="MotorcycleRentalAllowance" class="font-weight-bold" />
                                        <label id="errorMotorcycleRentalAllowance" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                </CRow>

                                <CRow>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold">Tunjangan Pulsa</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CInput id="PulseAllowance" type="number" v-model="PulseAllowance" class="font-weight-bold"/>
                                        <label id="errorPulseAllowance" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold">Tunjangan Kost</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CInput id="KostAllowance" type="number" v-model="KostAllowance" class="font-weight-bold" />
                                        <label id="errorKostAllowance" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                </CRow>
                            </div>
                            <br>
                            
                            <label class="form-label"><h4><b>Substraction</b></h4></label>
                            <div class="p-3" width="100%" style="border-style:solid; border-radius: 3px; border-width: thin; border-color:lightgrey">
                                <CRow>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold">Absen Subtraction</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CInput id="AbsenceSubtraction" type="number" v-model="AbsenceSubtraction" class="font-weight-bold" />
                                        <label id="errorAbsenceSubtraction" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold">Craft Subtraction</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CInput id="CraftSubtraction" type="number" v-model="CraftSubtraction" class="font-weight-bold" />
                                        <label id="errorCraftSubtraction" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol sm="3">
                                        <label class="form-label font-weight-bold">Insurance Subtraction</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="3">
                                        <CInput id="InsuranceSubtraction" type="number" v-model="InsuranceSubtraction" class="font-weight-bold" />
                                        <label id="errorInsuranceSubtraction" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                </CRow>
                            </div>
                            <br>
                            <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                            <label style="float: right;">&nbsp;&nbsp;</label>
                            <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                        </CCardBody>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import employeeHRService from '../Script/EmployeeHRService';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'EmployeeHRForm',
    props: ['reload'],
    components: {
        'date-picker' : datePicker,
    },
    data: function () {
        const now = new Date()

        return {
            ModalTitle : '',
            EmployeeId:'',
            Fullname:'',
            NIK: 0,
            NPWP: '',

            MarriedStatus: '',
            MarriedStatusData: [],

            Education: '',
            PhoneNumber: '',
            Email: '',
            StartWorkDate: '',
            LastWorkDate: '',

            EmploymentStatus: '',
            EmploymentStatusData: [],

            ContractPeriod: 0,
            ProbationPeriod: 0,
            WarningLetterReceived: '',

            Religion: '',
            ReligionData:[],

            Birthdate: '',
            BirthPlace: '',

            BloodGroup: '',
            BloodGroupData:[],

            Sex: '',
            SexData: [],

            Position: '',
            Department:'',
            Address: '',
            MealAllowance:'',
            TransportAllowance:'',
            FixedPositionalAllowance:'',
            DailyPositionalAllowance:'',
            MotorcycleRentalAllowance: '',
            TripAllowance: '',
            KostAllowance:'',
            PulseAllowance:'',
            InsuranceSubtraction:'',
            AbsenceSubtraction:'',
            BaseSalary:'',
            CraftSubtraction:'',
            AnnualPaidLeave:'',
            BaseSalary: '',
            BCAAccountName: '',
            BCAAccountNumber: '',

            Error: 0,
            SaveType: '',
            disabledPP: '',
            disabledCP: ''
        }
    },
    methods: {
        async addClick(){
            $(".form-error").css("display", "none");
            $(".save-button").show();
            this.ModalTitle = 'Add Employee HR : ';

            this.EmployeeId = '';
            this.Fullname = '';
            this.NIK = 0;
            this.NPWP = '';
            this.MarriedStatusData = await globalfunc.globalDropdown('ddl_status_married');
            this.MarriedStatus = this.MarriedStatusData[0].value;
            this.Education='';
            this.PhoneNumber='';
            this.Email='';
            this.StartWorkDate='';
            this.LastWorkDate='';
            this.EmploymentStatusData = await globalfunc.globalDropdown('ddl_employee');
            this.EmploymentStatus = this.EmploymentStatusData[0].value;
            this.onChangeDDLEmpStatus();

            this.ContractPeriod = 0;
            this.ProbationPeriod = 0;
            this.WarningLetterReceived = '';
            this.Salary= 0;
            this.BCAAccountName = '';
            this.BCAAccountNumber= 0;
            this.ReligionData = await globalfunc.globalDropdown('ddl_religion');
            this.Religion = this.ReligionData[0].value;
            this.Birthdate = '';
            this.BirthPlace = '';
            this.BloodGroupData = await globalfunc.globalDropdown('ddl_blood_type');
            this.BloodGroup = this.BloodGroupData[0].value;
            this.SexData = await globalfunc.globalDropdown('ddl_gender');
            this.Sex = this.SexData[0].value;
            this.Position = '';
            this.Department = '';
            this.Address = '';
            this.MealAllowance = 0;
            this.TransportAllowance = 0;
            this.FixedPositionalAllowance = 0;
            this.DailyPositionalAllowance = 0;
            this.MotorcycleRentalAllowance = 0;
            this.TripAllowance = 0;
            this.KostAllowance = 0;
            this.PulseAllowance = 0;
            this.InsuranceSubtraction = 0;
            this.AbsenceSubtraction = 0;
            this.BaseSalary = 0;
            this.CraftSubtraction = 0;
            this.AnnualPaidLeave = 0;

            this.disabledPP = true;
            this.disabledCP = false;

            this.SaveType = 'Add';
            window.$('#employeeHRModal').modal('show');
        },
        async editClick(id, View){
            $(".form-error").css("display", "none");
            $(".save-button").show();

            var data = await employeeHRService.getEmployeeHR(id);

            if (data.employment_status== "Kontrak"){
                this.disabledCP = true;
                this.disabledPP = false;

            }
            else if (data.employment_status == "Percobaan"){
                this.disabledPP = true;
                this.disabledCP = false;

            }
            else {
                this.disabledPP = false;
                this.disabledCP = false;

            }

            this.ModalTitle = 'Edit Employee HR : '
            this.EmployeeId = data.employee_id;
            this.Fullname = data.fullname;
            this.NIK = data.nik;
            this.NPWP = data.npwp;

            this.MarriedStatusData = await globalfunc.globalDropdown('ddl_status_married');
            this.MarriedStatus = data.married_status;

            this.Education = data.education;
            this.PhoneNumber = data.phone_number;
            this.Email = data.email;
            this.StartWorkDate = data.start_work_date;
            this.LastWorkDate = data.last_work_date;

            this.EmploymentStatusData = await globalfunc.globalDropdown('ddl_employee');
            this.EmploymentStatus = data.employment_status;
            
            this.ContractPeriod = data.contract_period;
            this.ProbationPeriod = data.probation_period;
            this.WarningLetterReceived = data.warning_letter_received;
            this.Salary = data.salary;

            this.ReligionData = await globalfunc.globalDropdown('ddl_religion');
            this.Religion = data.religion;

            this.Birthdate = data.birthdate;
            this.BirthPlace = data.birthplace;

            this.BloodGroupData = await globalfunc.globalDropdown('ddl_blood_type');
            this.BloodGroup = data.blood_group;

            this.SexData = await globalfunc.globalDropdown('ddl_gender');
            this.Sex = data.sex;

            this.Position = data.position;
            this.Department = data.department;
            this.Address = data.address;
            this.MealAllowance = data.meal_allowance;
            this.TransportAllowance = data.transport_allowance;
            this.FixedPositionalAllowance = data.fixed_positional_allowance;
            this.DailyPositionalAllowance = data.daily_positional_allowance;
            this.MotorcycleRentalAllowance = data.motorcycle_rental_allowance;
            this.TripAllowance = data.trip_allowance;
            this.KostAllowance = data.kost_allowance;
            this.PulseAllowance = data.pulse_allowance;

            this.InsuranceSubtraction = data.insurance_subtraction;
            this.AbsenceSubtraction = data.absence_subtraction;
            this.BaseSalary = data.base_salary;
            this.CraftSubtraction = data.craft_subtraction;
            this.AnnualPaidLeave = data.annual_paid_leave;

            this.BCAAccountName = data.bca_account_name;
            this.BCAAccountNumber = data.bca_account_number;

            this.SaveType = 'Edit';

            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Employee HR: ';
            }

            window.$('#employeeHRModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if(this.Fullname == ''){
                this.errorShow('errorFullname');
            }

            if(this.NIK == '' || this.NIK == null){
                this.errorShow('errorNIK');
            }

            if(this.Address == ''){
                this.errorShow('errorAddress');
            }

            if(this.Sex == ''){
                this.errorShow('errorSex');
            }

            if(this.BirthPlace == ''){
                this.errorShow('errorBirthPlace');
            }

            if(this.Birthdate == '' || this.Birthdate == null){
                this.errorShow('errorBirthdate');
            }

            if(this.PhoneNumber == ''){
                this.errorShow('errorPhoneNumber');
            }

            if(this.Email == ''){
                this.errorShow('errorEmail');
            }

            if(this.BloodGroup == '' || this.BloodGroup == null){
                this.errorShow('errorBloodGroup');
            }

            if(this.Education == ''){
                this.errorShow('errorEducation');
            }

            if(this.NPWP == ''){
                this.errorShow('errorNPWP');
            }

            if(this.MarriedStatus == ''){
                this.errorShow('errorMarriedStatus');
            }

            if(this.Religion == '' || this.Religion == null){
                this.errorShow('errorReligion');
            }
            
            if(this.StartWorkDate == '' || this.StartWorkDate == null){
                this.errorShow('errorStartWorkDate');
            }

            if(this.EmploymentStatus == ''){
                this.errorShow('errorEmploymentStatus');
            }

            if (this.EmploymentStatus == "Kontrak"){
                if(this.ContractPeriod == '' || this.ContractPeriod == 0 || this.ContractPeriod == null){
                    this.errorShow('errorContractPeriod');
                }
                else if (this.ContractPeriod < 0){
                    this.errorShow('errorContractPeriod', response.fieldRequiredNotMinus);
                }
            }
            else if (this.EmploymentStatus == "Percobaan"){
                if(this.ProbationPeriod == '' || this.ProbationPeriod == 0 || this.ProbationPeriod == null){
                    this.errorShow('errorProbationPeriod');
                }
                else if (this.ProbationPeriod < 0){
                    this.errorShow('errorProbationPeriod', response.fieldRequiredNotMinus);
                }
            }

            if(this.MealAllowance.toString() == '' || this.MealAllowance == null){
                this.errorShow('errorMealAllowance');
            }
            else if (this.MealAllowance < 0){
                this.errorShow('errorMealAllowance', response.fieldRequiredNotMinus);
            }

            if(this.TransportAllowance.toString() == '' || this.TransportAllowance == null){
                this.errorShow('errorTransportAllowance');
            }
            else if (this.TransportAllowance < 0){
                this.errorShow('errorTransportAllowance', response.fieldRequiredNotMinus);
            }

            if(this.FixedPositionalAllowance.toString() == '' || this.FixedPositionalAllowance == null){
                this.errorShow('errorFixedPositionalAllowance');
            }
            else if (this.FixedPositionalAllowance < 0){
                this.errorShow('errorFixedPositionalAllowance', response.fieldRequiredNotMinus);
            }

            if(this.DailyPositionalAllowance.toString() == '' || this.DailyPositionalAllowance == null){
                this.errorShow('errorDailyPositionalAllowance');
            }
            else if (this.DailyPositionalAllowance < 0){
                this.errorShow('errorDailyPositionalAllowance', response.fieldRequiredNotMinus);
            }

            if(this.MotorcycleRentalAllowance.toString() == '' || this.MotorcycleRentalAllowance == null){
                this.errorShow('errorMotorcycleRentalAllowance');
            }
            else if (this.MotorcycleRentalAllowance < 0){
                this.errorShow('errorMotorcycleRentalAllowance', response.fieldRequiredNotMinus);
            }

            if(this.TripAllowance.toString() == '' == this.TripAllowance == null){
                this.errorShow('errorTripAllowance');
            }
            else if (this.TripAllowance < 0){
                this.errorShow('errorTripAllowance', response.fieldRequiredNotMinus);
            }

            if(this.KostAllowance.toString() == '' || this.KostAllowance == null){
                this.errorShow('errorKostAllowance');
            }
            else if (this.KostAllowance < 0){
                this.errorShow('errorKostAllowance', response.fieldRequiredNotMinus);
            }

            if(this.PulseAllowance.toString() == '' || this.PulseAllowance == null){
                this.errorShow('errorPulseAllowance');
            }
            else if (this.PulseAllowance < 0){
                this.errorShow('errorPulseAllowance', response.fieldRequiredNotMinus);
            }

            if(this.InsuranceSubtraction.toString() == '' || this.InsuranceSubtraction == null){
                this.errorShow('errorInsuranceSubtraction');
            }
            else if (this.InsuranceSubtraction < 0){
                this.errorShow('errorInsuranceSubtraction', response.fieldRequiredNotMinus);
            }

            if(this.AbsenceSubtraction.toString() == '' || this.InsuranceSubtraction == null){
                this.errorShow('errorAbsenceSubtraction');
            }
            else if (this.AbsenceSubtraction < 0){
                this.errorShow('errorAbsenceSubtraction', response.fieldRequiredNotMinus);
            }

            if(this.BaseSalary.toString() == '' || this.BaseSalary == null){
                this.errorShow('errorBaseSalary');
            }
            else if (this.BaseSalary < 0){
                this.errorShow('errorBaseSalary', response.fieldRequiredNotMinus);
            }

            if(this.CraftSubtraction.toString() == '' || this.CraftSubtraction == null){
                this.errorShow('errorCraftSubtraction');
            }
            else if (this.CraftSubtraction < 0){
                this.errorShow('errorCraftSubtraction', response.fieldRequiredNotMinus);
            }

            if(this.AnnualPaidLeave.toString() == '' || this.AnnualPaidLeave == null){
                this.errorShow('errorAnnualPaidLeave');
            }
            else if (this.AnualPaidLeave < 0){
                this.errorShow('errorAnualPaidLeave', response.fieldRequiredNotMinus);
            }

            if(this.BCAAccountNumber.toString() == '' || this.BCAAccountNumber ==null){
                this.errorShow('errorBCAAccountNumber');
            }
            else if (this.BCAAccountNumber < 0){
                this.errorShow('errorBCAAccountNumber', response.fieldRequiredNotMinus);
            }
        },
        saveClick() {
            this.inputValidation();

            if(this.Error == 0){
                if(this.ProbationPeriod == "0" || this.ProbationPeriod == null){
                    this.ProbationPeriod = null;
                } else {
                    this.ProbationPeriod = this.ProbationPeriod.toString();
                }

                if(this.WarningLetterReceived == "0" || this.WarningLetterReceived == null){
                    this.WarningLetterReceived = null;
                } else {
                    this.WarningLetterReceived = this.WarningLetterReceived.toString();
                }

                const employeeHRData = {
                    fullname: this.Fullname,
                    nik: this.NIK.toString(),
                    npwp: this.NPWP,
                    married_status: this.MarriedStatus,
                    education: this.Education,
                    phone_number: this.PhoneNumber.toString(),
                    email: this.Email,
                    start_work_date: this.StartWorkDate,
                    last_work_date: this.LastWorkDate == "" ? null : this.LastWorkDate,
                    employment_status: this.EmploymentStatus,
                    contract_period: this.ContractPeriod.toString(),
                    probation_period: this.ProbationPeriod,
                    warning_letter_received: this.WarningLetterReceived,
                    religion: this.Religion,
                    birthdate: this.Birthdate,
                    birthplace: this.BirthPlace,
                    blood_group: this.BloodGroup,
                    sex: this.Sex,
                    position: this.Position,
                    department: this.Department,
                    address: this.Address,
                    meal_allowance: this.MealAllowance,
                    transport_allowance: this.TransportAllowance,
                    fixed_positional_allowance: this.FixedPositionalAllowance,
                    daily_positional_allowance: this.DailyPositionalAllowance,
                    motorcycle_rental_allowance: this.MotorcycleRentalAllowance,
                    trip_allowance: this.TripAllowance,
                    kost_allowance: this.KostAllowance,
                    pulse_allowance: this.PulseAllowance,
                    insurance_subtraction: this.InsuranceSubtraction,
                    absence_subtraction: this.AbsenceSubtraction,
                    base_salary: this.BaseSalary,
                    craft_subtraction: this.CraftSubtraction,
                    annual_paid_leave: this.AnnualPaidLeave,
                    base_salary: this.BaseSalary,
                    bca_account_name: this.BCAAccountName,
                    bca_account_number: this.BCAAccountNumber.toString()
                };

                this.$loading(true);
                //Add
                if(this.SaveType == 'Add'){
                    const variables = {
                        data : employeeHRData
                    }

                    employeeHRService.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#employeeHRModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                //Edit
                else if(this.SaveType == 'Edit'){                    
                    const variables = {
                        id : this.EmployeeId,
                        data : employeeHRData
                    }

                    employeeHRService.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#employeeHRModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode, message){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = message == undefined ? response.fieldRequired : message;
            if(this.Error < this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        onChangeDDLEmpStatus() {
            if (this.EmploymentStatus == "Kontrak"){
                this.disabledCP = true;
                this.disabledPP = false;
            }
            else if (this.EmploymentStatus == "Percobaan"){
                this.disabledPP = true;
                this.disabledCP = false;
            }
            else {
                this.disabledPP = false;
                this.disabledCP = false;
            }

            if (this.SaveType == 'Edit') {
                this.ContractPeriod = this.ContractPeriod == "0" ? 0 : this.ContractPeriod;
                this.ProbationPeriod = this.ProbationPeriod == "0" ? 0 : this.ProbationPeriod;
            }
            else {
                this.ContractPeriod = 0;
                this.ProbationPeriod = 0;
            }
        },
        onChangeDDLMarriedStatus(){
            // this.MarriedStatus = this.MarriedStatusData.value
        },
        disabledDate (date) {
            var year  = parseInt(this.StartWorkDate.split('-')[0])
            var month = parseInt(this.StartWorkDate.split('-')[1])-1
            var day = parseInt(this.StartWorkDate.split('-')[2])
            const startwork = new Date(year, month, day);
            return date < startwork ;
        }
    }
}
</script>
