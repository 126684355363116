<template>
    <div>
        <kendo-grid ref="grid"
                    :data-source="dataSource"
                    :pageable="true"
                    :filterable="true"
                    :sortable="true"
                    :columns=columns
                    :resizable="true">
        </kendo-grid>
    </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import attendanceService from '../Script/AttendanceService.js';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'AttendanceGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'deleteClick', 'period'],
    data(){        
        var Period = this.$props.period;
        var month = parseInt(Period.split("-")[0]);
        var year = parseInt(Period.split("-")[1]);
        
        return{
            dataSource: new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const variables = {
                                month: month,
                                year: year
                            };
                            return { query: attendanceService.readQuery(),
                                variables: variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    }
                },
                schema: {
                    data: function(response) {
                        if(response.data.GetAttendance == null){
                            return [];
                        }
                        else{
                            return response.data.GetAttendance;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetAttendance == null){
                            return 0;
                        }else{
                            return response.data.GetAttendance.length;
                        }
                    },
                },
            }),
            columns:  [
                { title: "Action", width: 150, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "attendance_id", title: "Kode Kehadiran", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "employee_Id", title: "Id Karyawan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, hidden: true},
                { field: "fullname", title: "Name Lengkap", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "department", title: "Departemen", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "position", title: "Posisi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "month", title: "Bulan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" } }, 
                { field: "year", title: "Tahun", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" } },
                { field: "total_attendance", title: "Total Kehadiran", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }},
                { field: "total_working_day", title: "Total Hari Kerja", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }},
                { field: "total_unpaid_leave", title: "Cuti tidak dibayar", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;"}, attributes: { "class": "k-text-right" } },
                { field: "total_paid_leave", title: "Cuti Berbayar", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }},
                { field: "created_at", title: "Tanggal Dibuat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" },
                  template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'dd-MM-yyyy') #"
                },
            ],
        }
    },
    mounted: function(){
        var deleteClick = this.$props.deleteClick;
        var editClick = this.$props.editClick;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;

        var Period = this.$props.period;
        var month = parseInt(Period.split("-")[0]);
        var year = parseInt(Period.split("-")[1]);
        
        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(month, year, dataItem.employee_Id, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            editClick(month, year, dataItem.employee_Id, true);
        })

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.attendance_id);
        })
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionButton("Attendance")
        }
    }
}
</script>

<style scoped>
</style>