<template>
    <div>
        <kendo-grid ref="grid"
                    :data-source="dataSource"
                    :filterable="filterableConfig"
                    :sortable="true"
                    :pageable="pageableConfig"
                    :columns=columns
                    :resizable="true">
        </kendo-grid>
    </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import employeeHRService from '../Script/EmployeeHRService.js';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'EmployeeHRGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'deleteClick'],
    data(){
        return{
            dataSource: new kendo.data.DataSource({
                        page: 1,
                        pageSize: kendo_grid.default_grid_pagesize,
                        serverPaging: true,
                        serverFiltering: true,
                        serverSorting: true,
                        transport: {
                            read: {
                                contentType: "application/json",
                                url: apiBaseUrl,
                                type: "POST",
                                data: function(e) {
                                    const paging = {
                                    Page : {Limit: e.pageSize,Page: e.page},
                                    Filter : globalfunc.filterQueryBuilder(e.filter),
                                    OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                                    }
                                    const variables = {
                                        paging : paging
                                    }
                                    return { query: employeeHRService.readQuery(),
                                            variables: variables};
                                },
                                beforeSend: function (req) {
                                    req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                                    req.setRequestHeader('Path', window.location.pathname);
                                }
                            },
                            parameterMap: function(options, operation) {
                                return  kendo.stringify({
                                    query: options.query,
                                    variables: options.variables
                                });
                            }
                        },
                        schema: {
                            data: function(response) {
                                if(response.data.GetEmployee.Employee == null){
                                    return [];
                                }
                                else {
                                    return response.data.GetEmployee.Employee;
                                }
                                
                            },
                            total: function(response) {
                                if(response.data.GetEmployee.Employee == null){
                                    return [];
                                }
                                else {
                                    return response.data.GetEmployee.Total;
                                }
                                
                            }
                        },
                }),
            columns:  [
                { title: "Action", width: 150, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "fullname", title: "Nama Lengkap", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "phone_number", title: "No. Telp", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },},
                { field: "email", title: "Email", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "employment_status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },

            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        }
    },
    mounted: function(){
        var deleteClick = this.$props.deleteClick;
        var editClick = this.$props.editClick;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;
        
        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.employee_id, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.employee_id, true);
        })

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.employee_id);
        })
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionButton("Employee HR")
        }
    }
}
</script>

<style scoped>
</style>